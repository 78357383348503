
import axios from 'axios'

  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_URL}api`
  });
  
  instance.interceptors.request.use(function (config) {
    let user = localStorage.getItem('user') == "undefined" ? null : JSON.parse(localStorage.getItem('user'));
  
  if(user?.token){
   
        config.headers.Authorization = `Bearer ${user?.token}`
    }
  
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

 
  instance.interceptors.response.use((response)=>response, async (error)=>{

          if (error.message === "Network Error") {
            error = "Internet connection error. Please check your internet connection.";
          } else {
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem('user');
                    error = error.response.data.message;
                    break;
                case 422:
                    error = Object.values(error.response.data.errors)[0][0];
                    break;
                case 404:
                    error = error.response.data.message;
                    break;
                default:
                    error = "An error occurred: " + error.message;
            }
          }
 
 


    return Promise.reject(error)
  });


export default instance
export const state = {
    type: null,
    message: null,
    isNotify: null,
};

export const mutations = {
    SET_NOTIFICATIONS(state, newValue) {
        state.isNotify = newValue;
    },
    success(state, message) {
        state.type = 'success';
        state.message = message;
    },
    error(state, message) {
        state.type = 'danger';
        state.message = message;
    },
    clear(state) {
        state.type = null;
        state.message = null;
    }
};
export const getters = {
     isNotify(state) {
        return state.isNotify
    },
    
}

export const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    },
    isNotification({commit},{payload}={}){
        commit('SET_NOTIFICATIONS',payload);
     }
};

import { getFirebaseBackend } from '../../helpers/firebase/authUtils'
import axios from '../../api/axios';
import endPoints from '../../api/end-points';
export const state = {
    currentUser: localStorage.getItem('user'),
    resetPasswordEmail: localStorage.getItem('resetPasswordMail'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('authUser', newValue)
    },
    SET_RESET_PASSWORD_EMAIL(state, newValue) {
        state.resetPasswordEmail = newValue
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    currentUser(state) {
        return state.currentUser
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    logIn({ commit }, { email, password }) {
        let loginData = {
            "email": email,
            "password": password,
        }
        return new Promise((resolve, reject) => {
            try {
                axios.post('/login', loginData)
                    .then((response) => {
                        // console.log(response.data.result);
                        let user = JSON.stringify(response.data.result);
                        commit('SET_CURRENT_USER', user);
                        localStorage.setItem('user', user);
    
                        let fcmToken = localStorage.getItem("fcmToken");
                        let formData = {
                            fcm_token: fcmToken,
                        };
    
                        return axios.post(endPoints.createFcmToken, formData);
                    })
                    .then((response) => {
                        console.log(response.data.message);
                        localStorage.removeItem('resetPasswordMail');
                         window.location.href = "/";
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    
                    });
            } catch (error) {
                console.log("Error:", error);
                reject(error);
            }
        });
    },    
    // Logs out the current user.
    logOut({ commit }) {
        axios.post('/logout')
            .then((response) => {
                let user = localStorage.getItem('user') == "undefined" ? null : JSON.parse(localStorage.getItem('user'));
                axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
                localStorage.clear(),

                sessionStorage.removeItem('authUser');
                console.log(response)
                console.log(commit);
                // alert('here');
                // commit('SET_CURRENT_USER', null);
                window.location.href = "/login";
            });
    },

    // register the user
    register({ commit, dispatch, getters }, { username, email, password,phoneNo,confirmPassword } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        let registerData = {
            "name": username,
            "email": email,
            "password": password,
            "password_confirmation": confirmPassword,
            "phone": phoneNo
        }
        // console.log(registerData);

        return new Promise((resolve, reject) => {

           try{
            // alert("here");
            axios.post('/register', registerData)
            .then((response) => {
                const regUser = response
            console.log(commit,regUser)
             
            location.href = '/login';
               
            }).catch(error => {

                // console.log(error,'--------------error--------');
                reject(error);
                // dispatch('notification/error', error, { root: true });

            });
           }catch(error){
             console.log("Error : " , error);
           }

        })
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email,router,toast } = {}) {
         if (getters.loggedIn) return dispatch('validate')
        let emailIt = {
            "email": email,
        }
        commit('SET_RESET_PASSWORD_EMAIL',email);
        localStorage.setItem('resetPasswordMail', email);
         return new Promise((resolve, reject) => {

            try{
 
             axios.post(endPoints.resetPassword, emailIt)
             .then((response) => {
                 toast.success(response.data.message, {
                });
                  router.push({name: 'Code-authentication'})
                
             }).catch(error => {
 
                toast.open({
                    message: error,
                    type: 'error',
                 });
                 reject(error);
                 // dispatch('notification/error', error, { root: true });
 
             });
            }catch(error){
         console.log("Error : " , error);
            }
 
         })
    },
    updateProfileInfo({ commit }, { response } = {}) {

    let user = response; 
    let oldUser = JSON.parse(localStorage.getItem('user'));
    let token = oldUser.token;
    let id = oldUser.id;
    user.id=id;
    user.token = token;
     let updatedUserString = JSON.stringify(user);

    commit('SET_CURRENT_USER', user);
    localStorage.setItem('user', updatedUserString);

    },


    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        const user = getFirebaseBackend().getAuthenticatedUser();
        commit('SET_CURRENT_USER', user)
        return user;
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}

<template>
  <div id="layout__wrapper">
    <router-view />
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
// import appConfig from "@/app.config";
import { notificationMethods } from "@/state/helpers";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { useStore } from 'vuex';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAN7ZraHiKUc8b3qZrd4dgosNig1qtfJCE",
  authDomain: "unlimitedcarwash-ca3fb.firebaseapp.com",
  projectId: "unlimitedcarwash-ca3fb",
  storageBucket: "unlimitedcarwash-ca3fb.appspot.com",
  messagingSenderId: "526919348091",
  appId: "1:526919348091:web:f8b86da05b85bc900297de",
  measurementId: "G-HXPQBDCELY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log(app);

// Initialize Firebase Messaging
const messaging = getMessaging();
  const store = useStore(); 

// Handle incoming messages
onMessage(messaging, (payload) => {
  // console.log('Message received. ', payload);
  store.dispatch('notification/isNotification',{payload:payload})
});

// Request permission and get token
getToken(messaging, { vapidKey: 'BBfzeA3O0OhkZZ5ea8xDi02JU_iss2ozdnFwZDariz11jb8X2Vpi1aG4Lpf08VPxs8MhjEfIq3JJ6I9ZgYGb2Pg' })
  .then((currentToken) => {
    if (currentToken) {
      localStorage.setItem("fcmToken", currentToken);
      // console.log("Token is:", currentToken);
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  })
  .catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
const route = useRoute();
// Lifecycle hooks
onMounted(() => {
  // Any code you want to run when the component is mounted
});

// Watchers
watch(() => route, () => {
  notificationMethods.clear();
});

// comment option api data to remove push notification issues

// export default {
//   name: "app",
//   page: {
//      titleTemplate(title) {
//       title = typeof title === "function" ? title(this.$store) : title;
//       return title ? `${title} | ${appConfig.title}` : appConfig.title;
//     },
//   },
//   methods: {
//     clearNotification: notificationMethods.clear,
//   },
//    watch: {
    
//     $route() {
//        this.clearNotification();
//     },
//   },
//   mounted() {
    
//   }
// };
</script>


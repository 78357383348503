import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import store from '@/state/store';
import { vMaska } from "maska";
import vClickOutside from "v-click-outside";
import VueApexCharts from "vue3-apexcharts";
import CKEditor from '@ckeditor/ckeditor5-vue';
import BootstrapVueNext from 'bootstrap-vue-next';
import { simplebar } from 'simplebar-vue'
import Swal from 'sweetalert2'
import ToastPlugin from 'vue-toast-notification';

import '@/assets/scss/app.scss';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'bootstrap/dist/js/bootstrap.bundle';
import "leaflet/dist/leaflet.css";
import "@vueform/multiselect/themes/default.css";
import "@vueform/slider/themes/default.css";
import 'vue-toast-notification/dist/theme-bootstrap.css';
import axiosPlugin from './plugin/axios-plugin';

const app = createApp(App);

// Use plugins and directives
app.use(store)
   .use(router)
   .use(VueApexCharts)
   .use(BootstrapVueNext)
   .use(i18n)
   .directive("maska", vMaska)
   .use(CKEditor)
   .use(axiosPlugin)
   .use(ToastPlugin)
   .use(simplebar)
    .use(vClickOutside);

// Make Swal available globally
app.config.globalProperties.$swal = Swal;
app.mount('#app');